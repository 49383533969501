import { appendLog } from '#/utils/logging';
import { showBlue, showGreen, showRed, showYellow } from '#/views/layout';
import { stopMovie } from '#/utils/video';

import './index.scss';

import '#/views/movie-list';
import '#/views/river-info';

window.addEventListener('keydown', (e) => {
  switch (e.which) {
    case VK_BLUE:
      showBlue();
      $('#blue-button').focus();
      break;
    case VK_RED:
      showRed();
      $('#red-button').focus();
      break;
    case VK_GREEN:
      showGreen();
      $('#green-button').focus();
      break;
    case VK_YELLOW:
      showYellow();
      $('#yellow-button').focus();
      break;
    case VK_BACK:
      stopMovie();
      break;
    case VK_ENTER:
      break;
    case VK_LEFT:
      break;
    case VK_RIGHT:
      break;
    case 27:
      // ESC
      stopMovie();
      break;
    case VK_DBUTTON:
      location.reload();
      break;
    default:
      break;
  }
});

/*
$(document).on('focus', '#movie-thumbnails .thumbnail .btn.btn-primary', (e) => {
  const $target = $(e.target);

  $target.parents('.thumbnail').addClass('focus');
});

$(document).on('blur', '#movie-thumbnails .thumbnail .btn.btn-primary', (e) => {
  const $target = $(e.target);

  $target.parents('.thumbnail').removeClass('focus');
});

$(document).on('click', '#movie-thumbnails .thumbnail .btn.btn-primary', (e) => {
  e.preventDefault();
});
*/

$('#blue-button').on('click', () => {
  showBlue();
});

$('#red-button').on('click', () => {
  showRed();
});

$('#green-button').on('click', () => {
  showGreen();
});

$('#yellow-button').on('click', () => {
  showYellow();
});

DashTVPlayer.LogHandler.log = (str, color) => {
  appendLog('[H-LOG]' + str);
};

DashTVPlayer.Debug.log = (msg) => {
  appendLog('[LOG  ] ' + msg);
};
DashTVPlayer.Debug.debug = (msg) => {
  appendLog('[DEBUG] ' + msg);
};
DashTVPlayer.Debug.info = (msg) => {
  appendLog('[INFO ] ' + msg);
};
DashTVPlayer.Debug.warn = (msg) => {
  appendLog('[WARN ] ' + msg);
};
DashTVPlayer.Debug.error = (msg) => {
  appendLog('[ERROR] ' + msg);
};
