export function appendLog(message: string): void {
  const pre = document.getElementById('logger-area') as HTMLParagraphElement;

  if (pre) {
    const span = document.createElement('span');
    span.innerText = message;

    if (pre.children.length !== 0) {
      pre.appendChild(document.createElement('br'));
    }

    pre.appendChild(span);

    while (pre.firstChild && pre.children.length > 1000) {
      pre.removeChild(pre.firstChild);
    }
  }
}

// @ts-ignore
window.appendLog = appendLog;
