import { startMovie } from '#/utils/video';
import { rivers } from '#/views/river-info';

interface VideoInfo {
  /**
   * ID
   */
  readonly id: number;

  /**
   * サムネイル画像の URL
   */
  readonly thumbnailUrl: string;

  /**
   * MPEG-DASH 形式の動画の URL
   */
  readonly dashUrl: string;

  /**
   * HLS 形式の動画の URL
   */
  readonly hlsUrl: string;

  readonly live: boolean;

  /**
   * 動画のタイトル
   */
  readonly title: string;

  /**
   * 動画の詳細
   */
  readonly description: string;
}

interface VideoInfoGroup {
  readonly title: string;

  readonly videos: ReadonlyArray<VideoInfo>;
}

const videoGroups: ReadonlyArray<VideoInfoGroup> = [
  {
    title: '河川監視カメラ',
    videos: [
      ...rivers.map((river) => {
        return {
          id: river.id,
          thumbnailUrl: river.thumbnailUrl,
          dashUrl: river.dashUrl,
          hlsUrl: river.hlsUrl,
          live: river.live,
          title: `${river.riverName} (${river.location})`,
          description: river.location,
        };
      }),
    ],
  },
  {
    title: 'ミハル川の動物たち',
    videos: [
      {
        id: 1,
        thumbnailUrl: './image/vod/thumbnails/M1000007.jpg',
        dashUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000007/dash/M1000007.mpd',
        hlsUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000007/hls/M1000007.m3u8',
        live: false,
        title: '白い鳥',
        description: 'ミハル通信ブース内に設置したライブカメラの映像をお送りいたします。',
      },
      {
        id: 2,
        thumbnailUrl: './image/vod/thumbnails/M1000014.jpg',
        dashUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000014/dash/M1000014.mpd',
        hlsUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000014/hls/M1000014.m3u8',
        live: false,
        title: '水鳥達',
        description: '古河電気工業ブース内に設置したライブカメラの映像をお送りいたします。',
      },
      {
        id: 3,
        thumbnailUrl: './image/vod/thumbnails/M1000025.jpg',
        dashUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000025/dash/M1000025.mpd',
        hlsUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000025/hls/M1000025.m3u8',
        live: false,
        title: 'サギ',
        description:
          '鎌倉工場からのライブストリーミングが視聴できます。工場内に設置された鎌倉の模型の様子がライブ配信されます。',
      },
      {
        id: 4,
        thumbnailUrl: './image/vod/thumbnails/M1000026.jpg',
        dashUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000026/dash/M1000026.mpd',
        hlsUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000026/hls/M1000026.m3u8',
        live: false,
        title: '飛び立った後',
        description: '箱根の山道をバイクで駆け抜ける前面展望映像です。',
      },
      {
        id: 5,
        thumbnailUrl: './image/vod/thumbnails/M1000028.jpg',
        dashUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000028/dash/M1000028.mpd',
        hlsUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000028/hls/M1000028.m3u8',
        live: false,
        title: 'アオサギ',
        description: '箱根の山道をバイクで駆け抜ける前面展望映像です。',
      },
      {
        id: 6,
        thumbnailUrl: './image/vod/thumbnails/M1000031.jpg',
        dashUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000031/dash/M1000031.mpd',
        hlsUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000031/hls/M1000031.m3u8',
        live: false,
        title: '鯉の群れ',
        description: '箱根の山道をバイクで駆け抜ける前面展望映像です。',
      },
    ],
  },
];

const $thumbnailOriginal = $(`
  <button type="button" class="cts-video-thumbnail">
    <div class="embed-responsive embed-responsive-16by9">
      <img alt="..." class="embed-responsive-item" src="./image/thumbnails/empty.png" />
    </div>
    <div class="cts-video-thumbnail__caption">
      <h4></h4>
    </div>
  </button>
`);

function createVideoInfoViewer(item: VideoInfo): JQuery {
  const $thumbnail = $thumbnailOriginal.clone();

  $thumbnail.find('img').attr('src', item.thumbnailUrl);
  $thumbnail.find('h4').text(item.title);
  // $thumbnail.find('p').text(item.description);

  if (item.live) {
    $thumbnail.find('.cts-video-thumbnail__caption').append($('<span class="label label-danger">ライブ配信</span>'));
  } else {
    $thumbnail.find('.cts-video-thumbnail__caption').append($('<span class="label label-success">アーカイブ</span>'));
  }

  $thumbnail.on('click', (e) => {
    e.preventDefault();

    startMovie(item.dashUrl, item.hlsUrl);
  });

  return $thumbnail;
}

function createVideoInfoGroupViewer($area: JQuery, group: VideoInfoGroup): void {
  $area.append(
    $(`
    <div class="row">
      <div class="col-md-12">
        <h2>${group.title}</h2>
      </div>
    </div>
  `)
  );

  const $row = $('<div class="row"></div>');
  let $cols: JQuery[] = [];

  for (let i = 0; i < group.videos.length; i++) {
    const item = group.videos[i];
    const $thumbnail = createVideoInfoViewer(item);
    const $col = $('<div class="col-md-2"></div>');
    $col.append($thumbnail);

    if ($cols.length === 6) {
      const $r = $row.clone();

      for (const $c of $cols) {
        $r.append($c);
      }

      $area.append($r);
      $cols = [$col];
    } else {
      $cols.push($col);
    }
  }

  if ($cols.length > 0) {
    const $r = $row.clone();

    for (const $c of $cols) {
      $r.append($c);
    }

    $area.append($r);
  }
}

function createVideoInfoListViewer(groups: ReadonlyArray<VideoInfoGroup>): void {
  const $area = $('#cts-video-thumbnails');
  $area.empty();

  for (const group of groups) {
    createVideoInfoGroupViewer($area, group);
  }

  /*
  const $row = $('<div class="row"></div>');
  let $cols: JQuery[] = [];

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const $thumbnail = createVideoInfoViewer(item);
    const $col = $('<div class="col-md-2"></div>');
    $col.append($thumbnail);

    if ($cols.length === 6) {
      const $r = $row.clone();

      for (const $c of $cols) {
        $r.append($c);
      }

      $area.append($r);
      $cols = [$col];
    } else {
      $cols.push($col);
    }
  }

  if ($cols.length > 0) {
    const $r = $row.clone();

    for (const $c of $cols) {
      $r.append($c);
    }

    $area.append($r);
  }
  */
}

$(() => {
  createVideoInfoListViewer(videoGroups);
});
