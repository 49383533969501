const bluePage = $('#blue-page');
const redPage = $('#red-page');
const greenPage = $('#green-page');
const yellowPage = $('#yellow-page');

export function showBlue(): void {
  bluePage.show();
  redPage.hide();
  greenPage.hide();
  yellowPage.hide();
}

export function showRed(): void {
  bluePage.hide();
  redPage.show();
  greenPage.hide();
  yellowPage.hide();
}

export function showGreen(): void {
  bluePage.hide();
  redPage.hide();
  greenPage.show();
  yellowPage.hide();
}

export function showYellow(): void {
  bluePage.hide();
  redPage.hide();
  greenPage.hide();
  yellowPage.show();
}
