import { inHybridcast } from '#/utils/hybridcast';
import { startMovie } from '#/utils/video';

export interface RiverInfo {
  /**
   * ID
   */
  readonly id: number;

  /**
   * 河川名
   */
  readonly riverName: string;

  /**
   * 位置
   */
  readonly location: string;

  readonly cameraPointId: string;

  /**
   * サムネイル画像の URL
   */
  readonly thumbnailUrl: string;

  /**
   * MPEG-DASH 形式の動画の URL
   */
  readonly dashUrl: string;

  /**
   * HLS 形式の動画の URL
   */
  readonly hlsUrl: string;

  readonly live: boolean;
}

export const rivers: ReadonlyArray<RiverInfo> = [
  {
    id: 1,
    riverName: 'ミハル川',
    location: '電工古河市中心部',
    cameraPointId: 'cts-river-info-camera-point--furukawa-central',
    thumbnailUrl: './image/river-info/thumbnails/furukawa-central.jpg',
    dashUrl: 'https://demo-ott.multicdnet.net/dash/stream2/manifest.mpd',
    hlsUrl: 'https://demo-ott.multicdnet.net/dash/stream2/playlist.m3u8',
    live: true,
  },
  {
    id: 2,
    riverName: 'ミハル川',
    location: '電工古河市付近',
    cameraPointId: 'cts-river-info-camera-point--near-furukawa',
    thumbnailUrl: './image/river-info/thumbnails/near-furukawa.jpg',
    dashUrl: 'https://video.cts-2023.miharu-demo.com/river-info/near-furukawa/dash/M1000007.mpd',
    hlsUrl: 'https://video.cts-2023.miharu-demo.com/river-info/near-furukawa/hls/M1000007.m3u8',
    live: false,
  },
  {
    id: 3,
    riverName: 'ミハル川',
    location: '村役場前',
    cameraPointId: 'cts-river-info-camera-point--village-office',
    thumbnailUrl: './image/river-info/thumbnails/village-office.jpg',
    dashUrl: 'https://demo-ott.multicdnet.net/dash/stream/manifest.mpd',
    hlsUrl: 'https://demo-ott.multicdnet.net/dash/stream/playlist.m3u8',
    live: true,
  },
  {
    id: 4,
    riverName: 'ミハル川',
    location: 'シビ川合流地点',
    cameraPointId: 'cts-river-info-camera-point--rivers-converged',
    thumbnailUrl: './image/river-info/thumbnails/rivers-converged.jpg',
    dashUrl: 'https://video.cts-2023.miharu-demo.com/river-info/rivers-converged/dash/M1000005.mpd',
    hlsUrl: 'https://video.cts-2023.miharu-demo.com/river-info/rivers-converged/hls/M1000005.m3u8',
    live: false,
  },
  {
    id: 5,
    riverName: 'シビ川',
    location: 'シビ川上流',
    cameraPointId: 'cts-river-info-camera-point--c-and-b-upstream',
    thumbnailUrl: './image/river-info/thumbnails/c-and-b-upstream.jpg',
    dashUrl: 'https://video.cts-2023.miharu-demo.com/river-info/c-and-b-upstream/dash/M1000029.mpd',
    hlsUrl: 'https://video.cts-2023.miharu-demo.com/river-info/c-and-b-upstream/hls/M1000029.m3u8',
    live: false,
  },
  {
    id: 6,
    riverName: 'ミハル川',
    location: '忍者村付近',
    cameraPointId: 'cts-river-info-camera-point--miharu-upstream',
    thumbnailUrl: './image/river-info/thumbnails/miharu-upstream.jpg',
    dashUrl: 'https://video.cts-2023.miharu-demo.com/river-info/miharu-upstream/dash/M1000012.mpd',
    hlsUrl: 'https://video.cts-2023.miharu-demo.com/river-info/miharu-upstream/hls/M1000012.m3u8',
    live: false,
  },
];

const $thumbnailOriginal = $(`
  <button type="button" class="cts-river-info-thumbnail">
    <div class="embed-responsive embed-responsive-16by9">
      <img alt="..." class="embed-responsive-item" src="./image/thumbnails/empty.png" />
    </div>
    <div class="cts-river-info-thumbnail__caption">
      <h3></h3>
      <h4></h4>
    </div>
  </button>
`);

function selectCameraPoint(id: string): void {
  const points = jQuery('.cts-river-info-camera-point');
  const length = points.length;

  for (let i = 0; i < length; i++) {
    const $point = jQuery(points[i]);

    if ($point.attr('id') === id) {
      $point.addClass('cts-river-info-camera-point--selected');
    } else {
      $point.removeClass('cts-river-info-camera-point--selected');
    }
  }
}

function showRiverInfoDetail(item: RiverInfo): void {
  const $table = jQuery('#cts-river-info-detail');

  $table.find('#cts-river-info-details__river-name').text(`${item.riverName} (${item.location})`);
  $table.find('#cts-river-info-details__location').text(item.location);
}

function selectRiverInfo(info: RiverInfo): void {
  selectCameraPoint(info.cameraPointId);
  showRiverInfoDetail(info);

  jQuery('#cts-river-info-play-live-camera').attr('data-river-info-id', info.id);
}

export function createRiverInfoViewer(item: RiverInfo): JQuery {
  const $thumbnail = $thumbnailOriginal.clone();

  $thumbnail.find('img').attr('src', item.thumbnailUrl);
  $thumbnail.find('h3').text(item.riverName);
  $thumbnail.find('h4').text(item.location);

  if (item.live) {
    $thumbnail
      .find('.cts-river-info-thumbnail__caption')
      .append($('<span class="label label-danger">ライブ配信</span>'));
  } else {
    $thumbnail
      .find('.cts-river-info-thumbnail__caption')
      .append($('<span class="label label-success">アーカイブ</span>'));
  }

  $thumbnail.on('click', (e) => {
    e.preventDefault();

    if (inHybridcast()) {
      // TV で見ている時はクリックで動画を再生する
      startMovie(item.dashUrl, item.hlsUrl);
    } else {
      selectRiverInfo(item);
    }
  });

  $thumbnail.on('focus', (e) => {
    if (inHybridcast()) {
      selectRiverInfo(item);
    }
  });

  return $thumbnail;
}

export function createRiverInfoListViewer(list: ReadonlyArray<RiverInfo>): void {
  const $area = $('#cts-river-info-thumbnails');
  $area.empty();

  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    const $thumbnail = createRiverInfoViewer(item);
    const $col = $('<div class="col-md-2"></div>');

    $col.append($thumbnail);
    $area.append($col);
  }
}

$(() => {
  createRiverInfoListViewer(rivers);

  jQuery('#cts-river-info-play-live-camera').on('click', (e) => {
    e.preventDefault();

    const $button = jQuery(e.currentTarget);
    const id = parseInt($button.attr('data-river-info-id')!);

    for (const river of rivers) {
      if (river.id === id) {
        startMovie(river.dashUrl, river.hlsUrl);
        break;
      }
    }
  });
});
